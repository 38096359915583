import React, { useState } from "react";
import ImageWrapper from "./ImageWrapper";
import Link from "next/link";

// import Zoom from "react-medium-image-zoom";
// import "react-medium-image-zoom/dist/styles.css";

import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";

function CmsCardGroup({ component, page_id = "" }) {
  const [zoomIndex, setZoomIndex] = useState(0);
  const [isZoomed, setIsZoomed] = useState(false);

  const images = [];
  component.options.cards.map(function (card, i) {
    images.push({ url: card.image.url, title: "" });
  });

  return (
    <div
      // className={`card-group griglia grid-cols-${component.options.itemsPerRow}`}
      className={`card-group griglia`}
      id={component.id ?? "" }
    >
      {component.options.cards.map(function (card, i) {
        if (component.id == "gallery") {
          return (
            <div className="image relative" key={i}>
              <div className="cursor-pointer">
                <ImageWrapper
                  alt={` `}
                  src={card.image.url}
                  blur={card.image.urlp}
                  width={card.image.width}
                  height={card.image.height}
                  onClick={() => {
                    setZoomIndex(i);
                    setIsZoomed(true);
                  }}
                />
              </div>
            </div>
          );
        } else if (card.link_text != "") {
          return (
            <div className="card merda" key={i}>
              <div className="cardContent">
                <div
                  className="caption"
                  dangerouslySetInnerHTML={{ __html: card.caption }}
                />
              </div>
              <div className="image relative">
                {card.image.url != "" && (
                  <div className="cursor-pointer">
                    <ImageWrapper
                      alt={` `}
                      src={card.image.url}
                      blur={card.image.urlp}
                      // layout="responsive"
                      width={card.image.width}
                      height={card.image.height}
                      // priority={true}
                      onClick={() => {
                        setZoomIndex(i);
                        setIsZoomed(true);
                      }}
                    />
                  </div>
                )}
                <Link
                  href={card.url}
                  className="card-btn"
                  target={card.url.startsWith("http") ? "_blank" : "_self"}
                >
                  {card.link_text != "" ? card.link_text : "MORE >"}
                </Link>
              </div>
            </div>
          );
        } else {
          return (
            <div className="card" key={i}>
              <Link
                href={card.url}
                className="card-btn"
                target={card.url.startsWith("http") ? "_blank" : "_self"}
              >
                <div className="cardContent">
                  <div
                    className="caption"
                    dangerouslySetInnerHTML={{ __html: card.caption }}
                  />
                </div>
                <div className="image relative">
                  {card.image.url != "" && (
                    <ImageWrapper
                      alt={` `}
                      src={card.image.url}
                      blur={card.image.urlp}
                      // layout="responsive"
                      width={card.image.width}
                      height={card.image.height}
                      // priority={true}
                    />
                  )}
                </div>
              </Link>
            </div>
          );
        }
      })}
      {isZoomed ? (
        <Lightbox
          images={images}
          title=""
          startIndex={zoomIndex}
          onClose={() => {
            setIsZoomed(false);
          }}
        ></Lightbox>
      ) : (
        ""
      )}
    </div>
  );
}

export default CmsCardGroup;
